import { gql } from '@apollo/client';
import { menuList } from '../fragments/menuList';

export const getHeaderMenuListQuery = gql`
query GetHeaderMenuList{
 MenuListHeader(usePreview: false,tags:"advertisingHeader" ) {
   internalName
  menuSections{
    itemUrl
    itemIcon
    publicName
    internalName
  }
  
  }
}
`;
