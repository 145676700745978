import React, { useEffect, useState } from "react";
import { HEADER_HEIGHT } from "../../config";
import { useQuery } from "@apollo/client";
import { getHeaderMenuListQuery } from "../../graphQL/queries/getHeaderMenuList";
import { SideNav } from "./sideNav";
import { closex, menu } from "../icon";
import { useLockBodyScrollOnValue } from "../../helpers/useLockBodyScroll";
import { SocialButtons } from "../social";
import LogoutButton from "../Logout";
import LoginButton from "../Login";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";

export const Header = () => {
  const { isAuthenticated, isLoading, user ,loginWithRedirect} = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [headerData, setHeaderData] = useState([]);
  let [showSidebar, showSideBarSet] = useState(false);
  let close = () => showSideBarSet(false);

  useLockBodyScrollOnValue(showSidebar);
  const { data, error, loading } = useQuery(getHeaderMenuListQuery, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const results = data?.MenuListHeader[0]?.menuSections;
      const filteredItems = results.filter((item:  any) => item?.publicName !== "Competitive Spend");
      if(isAuthenticated){setHeaderData(results);}else{setHeaderData(filteredItems);}
    },
    onError: (error) => console.log(error),
  });
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
    
    <header className="primary-header">
      {showSidebar && (
        <div className="fixed inset-0 z-1000">
          <div className="h-full w-110 md:w-120 max-w-full flex flex-col bg-blue-darker">
                <button className="w-16 h-16 text-white my-2" onClick={close}>
                  {closex}
                </button>
                <div className="overflow-auto #00013a px-8">
                  <p className="mx-8 md:hidden border-b border-gray-dark border-opacity-50 text-2xl md:text-xl text-white text-left font-bold pt-4 pb-4 ml-8">
                     Welcome
                  </p>
                  <SideNav link={headerData} close={close}/>
                  <SocialButtons/>
                  {isAuthenticated && <div className="sm:hidden flex justify-center text-xl p-6" style={{'backgroundColor': "#00b1eb"}}>
                  <LogoutButton />
                  </div>}
                </div>
                {!isAuthenticated && <div className="sm:hidden flex justify-center text-xl p-6" style={{'backgroundColor': "#00b1eb"}}>
                
                  <React.Fragment><button className='inline-block text-white text-xl bold' onClick={() => loginWithRedirect()}>
                    Log In
                  </button>
                  </React.Fragment>
              </div>}
          </div>
        </div>
      )}

      <div className="z-100 sticky top-0 shadow-md">
        <div className="w-full">
          <div
            className="flex items-center gap-6 2lg:gap-0 px-0 pb-2 w-full"
            style={{ height: HEADER_HEIGHT }}
          >
            {/* Hide hamburger menu from desktop. */}
            <div className="displayham">
              <button
                className="w-10 h-10relative text-blue-darker pt-4 pl-6 top-3px"
                style={{ width: "7rem", color: "white" }}
                onClick={() => showSideBarSet(true)}
              >
                {menu}
                <div className="border-angle"></div>
              </button>
            </div>
            <div className="px-0 py-3  w-full">
              {/* Hide hamburger menu from desktop. */}

              <div
                className="flex flex-row justify-center md:items-center z-100 px-0 sticky top-0 shadow-md bold text-xl"
                style={{ height: "5.5rem" }}
              >
                <nav>
                  <ul className="flex flex-1 flex-row justify-end flex-no-wrap mx-4 pt-4 text-white xs:hidden">
                    {headerData?.map((header: any) => {
                      return (
                        <li
                          key={header?.publicName}
                          className={isAuthenticated?"text-lg px-4 bold ":"text-lg px-5 bold"}
                        >
                          <a href={header?.itemUrl}>
                            {header?.itemIcon && (
                              <img
                                src={header?.itemIcon}
                                className="sm:h-10 h-32px m-auto "
                                style={{width:"5rem"}}
                                alt="Sky logo"
                              />
                            )}

                            {header?.publicName}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
                    {isAuthenticated && <div className="hidden lg:visible md:visible sm:visible">  <LogoutButton /></div>}
                   {!isAuthenticated &&<p className=" hidden lg:visible md:visible sm:visible lg:mr-12 sm:mr-32"><LoginButton /></p>}
              </div>
            </div>
            {isOpen && (
              <div className="md:hidden bg-gray-700">
                <ul className="flex flex-1 flex-col justify-end flex-no-wrap px-2 text-white ">
                  {headerData?.map((header: any) => {
                    return (
                      <li key={header.publicName} className="text-lg px-5 bold">
                        <a href={header.itemUrl}>{header.publicName}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
    </>
  );
};
