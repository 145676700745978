import React from "react";
import { DocumentToReactComponents } from "./DocumentToReactComponents";
import { useAnalytics } from "../useAnalytics";
//import { VideoSrcScript } from './VideoSrcScript';

interface ComponentCardSection {
  iframeSrc: string;
  heading?: string;
  internalName: string;
  cardImageUrl?: {
    file?: string;
    title?: string;
  };
  cardTitlePosition?: string;
  imageLinkFile?: string;
  cardImagePosition?: string;
  cardImageLinkUrl?: string;
  cardTitle?: string;
  cardSubTitle?: string;
  cardDescription?: JSON;
  cardDescriptionTextAlignment?: string;
  cardDetailedDescription?: JSON;
  cardDetailedDescriptionAlignment?: string;
  brightCoveVideoId?: string;
  brightCoveVideoExperienceId?: string;
  links?: Links[];
  linkText?: string;
  linkUrl?: string;
}
interface ComponentCardProps {
  componentCardSectionTitle?: string;
  componentCards: ComponentCardSection;
  componentCardDisplayTile?: string;
}
interface Links {
  internalName?: string;
  linkUrl?: string;
  linkText: string;
}

const ComponentCardsComp = ({ componentCards }: ComponentCardProps) => {
  const {download} = useAnalytics();
  let displayLayout;
  switch (componentCards?.cardImagePosition) {
    case "Center":
      displayLayout = "";
      break;
    case "Right":
      displayLayout = "lg:grid lg:grid-cols-2";
      break;
    case "Left":
      displayLayout = "lg:grid lg:grid-cols-2";
      break;
    default:
      displayLayout = "";
      break;
  }

  let displayTextAlign;
  switch (
    componentCards?.cardDescriptionTextAlignment ||
    componentCards?.cardDetailedDescriptionAlignment
  ) {
    case "Center":
      displayTextAlign = "text-center";
      break;
    case "Right":
      displayTextAlign = "text-right";
      break;
    case "Left":
      displayTextAlign = "text-left";
      break;
    default:
      displayTextAlign = "text-left";
      break;
  }

  return (
    <div
      className={
        componentCards?.cardTitlePosition === "Right"
          ? "w-1/2 md:float-right"
          : "w-full"
      }
    >
      <div
        className={`card overflow-hidden ${displayLayout} gap-4 shadow-none pb-4 w-full`}
        style={{ borderRadius: 0 }}
      >
        <div
          className={`${
            componentCards?.cardImagePosition === "Right"
              ? "order-last"
              : "order-first"
          }`}
        >
          {componentCards?.cardImageUrl?.file && (
            <a onClick={()=>download(componentCards)} target="_blank" href={componentCards?.imageLinkFile}>
              <img src={componentCards?.cardImageUrl?.file as string} />
            </a>
          )}
        </div>

        <div
          className={
            componentCards?.cardTitle ? "mt-2 mx-auto py-3" : "mt-2 mx-8 py-3"
          }
        >
          {!componentCards?.iframeSrc &&
            componentCards?.cardTitle &&
            componentCards?.cardTitlePosition === "None" && (
              <div className="sky-h4 sm:sky-h4 md:sky-h4 bold text-h4 text-black">
                <div>{componentCards?.cardTitle}</div>
              </div>
            )}
          {!componentCards?.iframeSrc &&
            componentCards?.cardTitle &&
            componentCards?.cardTitlePosition === "Left" && (
              <div className="sky-h4 sm:sky-h4 md:sky-h4 float-left px-8 bold text-h4 text-black">
                <div>{componentCards?.cardTitle}</div>
              </div>
            )}

          {!componentCards?.iframeSrc &&
            componentCards?.cardTitle &&
            componentCards?.cardTitlePosition === "Right" && (
              <div className="sky-h4 sm:sky-h4 md:sky-h4-un-bold  bold float-right pb-8 text-h4 text-black">
                <div>{componentCards?.cardTitle}</div>
              </div>
            )}

          {componentCards?.links && (
            <>
              <div
                className={
                  componentCards?.cardTitlePosition === "Right"
                    ? "sky-h6 text-black bold sm:sky-h6 md:sky-h6 py-4 flex flex-col float-right mr-6 -mt-8 text-base"
                    : "text-base sky-h6 sm:sky-h6 md:sky-h6 py-4 flex flex-col order-last float-right bold mr-36 md:mr-80 -mt-8 text-black"
                }
              >
                {componentCards?.links?.map((link: any) => {

                  return (
                    <button onClick={()=>download(link)} className="sky-button sky-button--dark sky-bg-midnight resources">
                      <a
                        href={
                          link?.linkDocument?.url
                            ? link?.linkDocument?.url
                            : link?.linkUrl
                        }
                        target="_blank"
                      >
                        {link?.linkText}
                      </a>
                    </button>
                  );
                })}
              </div>
            </>
          )}

          <div className={componentCards?.iframeSrc ? "lg:flex inline" : ""}>
            {componentCards?.cardDescription && (
              <div
                className={`sky-h6 mt-8 md:sky-h7${
                  componentCards?.cardImageUrl?.file
                    ? "font-semibold "
                    : displayTextAlign
                } ${
                  componentCards?.iframeSrc
                    ? "pr-8 text-left text-2xl"
                    : "text-center "
                }`}
              >
                {componentCards?.iframeSrc && componentCards?.cardTitle && (
                  <div className="sky-h4 sm:sky-h4 md:sky-h4 mb-6 text-black text-5xl font-skyBook">
                    <div>{componentCards?.cardTitle}</div>
                  </div>
                )}
                {componentCards?.cardDescription && (
  <DocumentToReactComponents
    richtextEditorProps={componentCards.cardDescription as any}
  />
)}
              </div>
            )}

            {componentCards?.iframeSrc && componentCards?.iframeSrc !== "" && (
              <div className="mt-8">
                <iframe
                  src=""
                  width="600"
                  height="450"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            )}
            {componentCards?.cardDetailedDescription && (
              <div
                className={`${
                  componentCards?.cardImageUrl?.file
                    ? "font-semibold"
                    : displayTextAlign
                }`}
                style={{ fontSize: "1.25rem" }}
              >
                <DocumentToReactComponents
                  richtextEditorProps={
                    componentCards?.cardDetailedDescription as any
                  }
                />
              </div>
            )}
          </div>
          {componentCards?.linkUrl && (
            <div className="mt-4 text-center">
              <a onClick={()=>download(componentCards)}
                href={componentCards?.linkUrl} target="_blank" 
                className="text-lg font-bold sky-bg-daylight text-white  px-4 py-4 rounded-full"
              >
                {componentCards?.linkText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ComponentCard = ({
  componentCardSectionTitle,
  componentCards,
  componentCardDisplayTile,
}: ComponentCardProps) => {
  return (
    <>
      {componentCardSectionTitle && (
        <div className="px-4 py-14 sky-blue-gradient text-center text-white mb-6">
          <h1
            className="sky-h4 sm:sky-h4 md:sky-h4 mt-3 text-center text-4xl p-2"
            style={{ fontFamily: "MarkPro-Book SKY" }}
            dangerouslySetInnerHTML={{
              __html: componentCardSectionTitle as string,
            }}
          ></h1>
        </div>
      )}
      <div
        className={`pb-4 ${
          componentCards?.cardImageUrl?.file
            ? componentCards?.cardImagePosition === "None" ||
              componentCards?.cardImagePosition === "Center"
              ? "w-11/12 d-block mx-auto mt-12"
              : "lg:mx-16 md:mx-5 mx-4 mt-12"
            : "lg:mx-16 md:mx-5 mx-4 mt-12"
        }`}
      >
        <>
          <ComponentCardsComp componentCards={componentCards} />
        </>
      </div>
    </>
  );
};
