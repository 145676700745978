
export enum ComposePageContentType {
  WEB_CONTENT_SINGLE_BANNER = 'webContentSingleBanner',
  COMPONENT_CARD_SECTION = 'componentCardSection',
  POPUP_CARDS = 'advertisingPopupCardCollection',
  COMPONENT_CARD_COLLECTION = 'advertisingComponentCardCollection',
  COMPONENT_CARD = 'advertisingComponentCard',
}

const HORIZONTAL_VIDEO_PLAYER_URL =
  process.env.HORIZONTAL_VIDEO_PLAYER_URL ??
  'https://players.brightcove.net/6122285394001/Uk656dFgP_default/index.html?videoId=';


  const HEADER_HEIGHT = '5rem';
const pageErrorMessage = {
  header: 'Snap!',
  lineOne: 'Looks like something went wrong',
  lineTwo: 'Try again later...',
};
const GRAPHQL_URL= process.env.REACT_APP_GRAPHQL_URL!;
const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY!;
const CAPTCHA_ENABLED = process.env.REACT_APP_CAPTCHA_ENABLED!;
const AUTH0_LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL!;
const SPLIT_AUTHORIZATION_KEY = process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!;
const BACKEND_ENDPOINT = process.env.REACT_APP_ENDPOINT

const camelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

const formEmailPayload = (val: any) => {
  let payload: any = {};
  val.map((item: any) => {
    if (
      !item.key.includes("help") &&
      !item.key.includes("submit") &&
      !item.key.includes("option") &&
      !item.key.includes("empty")
    ) {
      let key = camelCase(item.value);
      payload[key] = item.inpValue;
      if(item.key === "captcha"){
        payload.captcha = item.inpValue;
      }
    }
  });
  return payload;
};
export {
    pageErrorMessage,
    HEADER_HEIGHT,
    HORIZONTAL_VIDEO_PLAYER_URL,
    GRAPHQL_URL,
    CAPTCHA_ENABLED,
    CAPTCHA_KEY,
    AUTH0_LOGOUT_URL,
    SPLIT_AUTHORIZATION_KEY, BACKEND_ENDPOINT,
    formEmailPayload
};
