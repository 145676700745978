
import { segment } from "./segment";

function useAnalytics() {
  const page = (title:string) => {
    segment.page(title);
  };

  const signUpForNL = (signUPForm: any, alertMessage: any) => {
    segment.signUpForNL(signUPForm, alertMessage);
  };

  const download = (linkData: any) => {
    segment.download(linkData);
  };
  const signedIn = (user: any) => {
    segment.signedIn(user);
  };
  const signedOut = () => {
    segment.signedOut();
  };
  const identify = (user: any) => {
    segment.identify(user);
  };
  const resetIdentity = () => {
    segment.resetIdentity();
  };
  return {
    page,
    signUpForNL,
    download,
    signedIn,
    signedOut,
    identify,
    resetIdentity,
  };
}

export { useAnalytics };
