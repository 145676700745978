import React from 'react';// Adjust the path as needed
import { useAuth0 } from '@auth0/auth0-react';
import { useAnalytics } from '../useAnalytics';

const LogoutButton = () => {
  const { logout,isAuthenticated } = useAuth0();
  const { resetIdentity,signedOut} = useAnalytics();
  const handleClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    signedOut();
    resetIdentity();
  };
  return (
    isAuthenticated ?
            <React.Fragment><button className="inline-block text-white text-xl bold mr-4" onClick={() =>
                handleClick()}>
      Log Out
    </button>
    </React.Fragment>:<></>
  );
};

export default LogoutButton;
