import React from "react";
import { HORIZONTAL_VIDEO_PLAYER_URL } from "../config";
import { DocumentToReactComponents } from "./DocumentToReactComponents";
import { useAnalytics } from "../useAnalytics";

interface ComponentCardSection {
  heading?: string;
  cardImageUrl?: {
    file?: string;
    title?: string;
  };
  cardImagePosition?: string;
  cardTitlePosition?: string;
  imageLinkFile?: string;
  cardImageLinkUrl?: string;
  cardTitle?: string;
  cardSubTitle?: string;
  cardDescription?: any;
  cardDescriptionTextAlignment?: string;
  cardDetailedDescription?: JSON;
  cardDetailedDescriptionAlignment?: string;
  brightCoveVideoId?: string;
  linkText?: string;
  linkUrl?: string;
  linkDocument?: any;
}
interface ComponentCardProps {
  componentCardSectionTitle: string;
  componentCards: ComponentCardSection[];
  componentCardDisplayTile: string;
}

export const ComponentCardCollection = ({
  componentCardSectionTitle,
  componentCards,
  componentCardDisplayTile,
}: ComponentCardProps) => {
  
  const {download} = useAnalytics();
  let displayTile;
  let objectFitTile: any, widthTile: any, heightTile: any;
  switch (componentCardDisplayTile?.replace(" ", "")) {
    case "Twotile":
      displayTile = "grid-cols-2 gap-4";
      widthTile = "40rem";
      heightTile = "auto";
      objectFitTile = "cover";
      break;

    case "Threetile":
      displayTile = "grid-cols-3 gap-4";
      widthTile = "40rem";
      heightTile = "18rem";
      objectFitTile = "cover";
      break;

    case "Fourtile":
      displayTile = "grid-cols-4 gap-0";
      widthTile = "auto";
      heightTile = "18rem";
      objectFitTile = "contain";
      break;

    default:
      displayTile = "grid-cols-1 gap-0";
      widthTile = "40rem";
      heightTile = "auto";
      objectFitTile = "cover";
      break;
  }

  return (
    <>
      <div className="flex flex-col mx-auto justify-start gap-4">
        {componentCardSectionTitle && (
          <div className="px-4 py-14 sky-blue-gradient text-center text-white">
            <h1
              className="sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3 text-center text-4xl p-2"
              style={{ fontFamily: "MarkPro-Book SKY" }}
              dangerouslySetInnerHTML={{
                __html: componentCardSectionTitle as string,
              }}
            ></h1>
          </div>
        )}
        <div
          className={`pb-4 grid lg:${displayTile} md:grid-cols-2 lg:mx-35 md:mx-5 mx-4 p-4 grid-cols-1 gap-7 px-12`}
        >
          {componentCards?.map((componentCardCollection: any) => {
            return (
              <div
                className="card text-left text-break"
                style={{ borderRadius: 0 }}
              >
                <div>
                  {componentCardCollection?.cardImageUrl?.file?.length > 0 && (
                    <a onClick={()=>download(componentCardCollection)} href={componentCardCollection?.imageLinkFile} target="_blank">
                      <img
                        alt={
                          componentCardCollection?.cardImageUrl?.file as string
                        }
                        src={
                          componentCardCollection?.cardImageUrl?.file as string
                        }
                        style={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          width: widthTile,
                          height: heightTile,
                          objectFit: objectFitTile,
                        }}
                      />
                    </a>
                  )}
                </div>

                {componentCardCollection?.linkUrl && (
                  <div
                    className="-mt-18 ml-8 "
                    style={{ position: "absolute" }}
                  >
                    <a onClick={()=>download(componentCardCollection)}
                      href={componentCardCollection?.linkUrl} target="_blank"
                      className="text-lg sky-bg-transparent sky-border-white text-white  px-12 py-4 rounded-full white-hover"
                    >
                      {componentCardCollection?.linkText}
                    </a>
                  </div>
                )}

                {componentCardCollection?.brightCoveVideoId && (
                  <div
                    style={{
                      position: "relative",
                      display: "block",
                      maxWidth: "1920px",
                    }}
                  >
                    <div style={{ paddingTop: "56.25%" }}>
                      <iframe
                        title={componentCardCollection?.cardTitle}
                        // src ={'https://players.brightcove.net/6122285394001/experience_63211edca1522b0025cf4d1d/index.html?videoId=6331602517112'}
                        src={
                          HORIZONTAL_VIDEO_PLAYER_URL +
                          componentCardCollection?.brightCoveVideoId
                        }
                        allow="encrypted-media"
                        className="absolute w-full h-full top-0 left-0 bottom-0"
                      ></iframe>
                    </div>
                  </div>
                )}

                <div className="mt-2 py-3">
                  <div
                    className="sky-h4 sm:sky-h4 md:sky-h4 mb-2"
                    style={{
                      color: "#000000",
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    <div>{componentCardCollection?.cardTitle}</div>
                  </div>
                  {componentCardCollection?.cardSubTitle && (
                    <div
                      className="sky-h6 sm:sky-h6 md:sky-h6 py-4"
                      style={{ color: "#000000", fontSize: "1rem" }}
                    >
                      <div>{componentCardCollection?.cardSubTitle}</div>
                    </div>
                  )}
                  {componentCardCollection?.cardDescription && (
                    <div
                      className="sky-h6 sm:sky-h7 md:sky-h7"
                      style={{
                        height: componentCardCollection?.linkUrl && "auto",
                      }}
                    >
                      <DocumentToReactComponents
                        richtextEditorProps={
                          componentCardCollection?.cardDescription
                        }
                      />
                    </div>
                  )}

                  {componentCardCollection?.linkText &&
                    componentCardCollection?.linkDocument?.url && (
                      <div
                        className="sky-h6 sm:sky-h6 md:sky-h6 py-4"
                        style={{ color: "#000000", fontSize: "1rem" }}
                      >
                        <button onClick={()=>download(componentCardCollection)} className="sky-button sky-button--dark sky-bg-midnight resources mx-0">
                          <a
                            href={componentCardCollection?.linkDocument?.url}
                            target="_blank"
                          >
                            {componentCardCollection?.linkText}
                          </a>
                        </button>
                      </div>
                    )}
                  {componentCardCollection?.links && (
                    <>
                      <div
                        className={
                          componentCardCollection?.cardTitlePosition === "Right"
                            ? "sky-h6 sm:sky-h6 md:sky-h6 py-4 flex flex-col float-right mr-6 -mt-8"
                            : "sky-h6 sm:sky-h6 md:sky-h6 py-4 flex flex-col order-last float-left -mt-8"
                        }
                        style={{
                          color: "#000000",
                          fontSize: "1rem",
                          fontWeight: "bold",
                          paddingTop: "2rem",
                        }}
                      >
                        {componentCardCollection?.links?.map((link: any) => {
                          return (
                            <button onClick={()=>download(link)}
                              className="sky-button sky-button--dark sky-bg-midnight resources"
                              style={{ marginRight: "-1.25rem" }}
                            >
                              {link?.linkDocument?.url &&<a href={link?.linkDocument?.url} target="_blank">
                                {link?.linkText}
                              </a>}
                              {!link?.linkDocument?.url &&<a href={link?.linkUrl} target="_blank">
                                {link?.linkText}
                              </a>}
                            </button>
                          );
                        })}
                      </div>
                    </>
                  )}
                  {componentCardCollection?.cardDetailedDescription && (
                    <div
                      style={{
                        height: componentCardCollection?.linkUrl && "auto",
                      }}
                    >
                      <p>
                        <DocumentToReactComponents
                          richtextEditorProps={
                            componentCardCollection?.cardDetailedDescription as any
                          }
                        />
                      </p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
